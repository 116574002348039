.keyword-search {
  --keyword-search-font-color: var(--wl-text-color-one);
  --keyword-search-font-size: var(--wl-font-size-xx-small);
  --keyword-search-bg-color: var(--wl-content-background-color-default);
}

.keyword-search {
  width: 100%;
  height: 100%;
  cursor: text;
  display: flex;
  position: relative;
  align-items: center;
  color: var(--keyword-search-font-color);
  background: var(--keyword-search-bg-color);
}

.keyword-search form {
  line-height: 85%;
  flex-grow: 1;
  width: 0;
}

.keyword-search input.keyword-input {
  padding: 0px;
  border: none;
  width: 170px;
  font-size: var(--keyword-search-font-size);
  color: var(--keyword-search-font-color);
}

.keyword-search input:focus {
  outline: none;
}

.keyword-search input::placeholder {
  color: var(--keyword-search-font-color);
}

.keyword-search svg {
  margin-right: 6px;
}

.keyword-search .dropdown-container {
  top: 0px;
  right: -8px;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: var(--new-header-keyword-search-border-color);
  width: fit-content;
}

.keyword-search .dropdown-container label {
  display: none;
}

.keyword-search .dropdown-container .dropdown-input-container {
  border: 0;
  height: 25px;
}

.keyword-search .dropdown-container .dropdown-input-container:before {
  padding: 0px 10px;
}

.keyword-search .dropdown-container input.dropdown-input {
  margin: 0;
  height: 25px;
  cursor: pointer;
  padding: 0px 30px 0px 12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: var(--keyword-search-font-color);
  font-size: var(--keyword-search-font-size);
  background: var(--new-header-keyword-search-border-color);
  text-align: center;
}

.keyword-search .select-search-type-container select:focus {
  outline: none;
}

@media screen and (max-width: 969px) {
  .keyword-search input.keyword-input,
  .keyword-search .dropdown-container input.dropdown-input {
    font-size: var(--wl-font-size-medium);
  }
}
